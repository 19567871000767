.primary-PatientAvailabilityBuilder-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Location-navigation {
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Navigation {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      .Home-img {
        height: 25px;
      }
    }
  }
  .secondary-PatientAvailabilityBuilder-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;

    .primary-text {
      font-size: 14px;
      font-weight: 200;
      margin: 8px 0px;
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif !important;

      .help {
        color: #4a70c6;
        cursor: pointer;
      }
    }

    .AvailabilityBuilder-img {
      width: 315px;
    }
  }

  .validate-text {
    display: flex;
    justify-content: center;
    color: #b60000;
  }

  .Button-container {
    display: flex;
    justify-content: center;
    height: 100px;
    padding: 30px;

    .Next-button {
      background-color: #6987ca;
    }
  }
}

@media screen and (max-width: 636px) {
  .primary-PatientAvailabilityBuilder-container {
    padding: 0px 10px;

    .secondary-PatientAvailabilityBuilder-container {
      .primary-text {
        padding: 0px 10px;
        font-size: 12px;
      }

      .AvailabilityBuilder-img {
        padding: 0px 0px 0px 10px;
      }
    }
  }
}
