.client-reschedule-late-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  // justify-content: space-evenly;

  .client-reschedule-late-inner-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;

    .reschedule-late-heading {
      color: #122f4e;
      font-weight: 580;
      font-family: Arial, Helvetica, sans-serif !important;
      font-size: 22px;
    }

    .reschedule-late-paragraph {
      color: #122f4e;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif !important;
      font-size: 16px;

      // .reschedule-late-tag {
      //   text-decoration: none;
      //   color: #122f4e;
      // }
    }

    .reschedule-late-home-button {
      width: 35%;
      background-color: #4a70c6;
      color: white;
    }

    .cancel-late-tag {
      color: blue !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 600px) {
    .reschedule-late-home-button {
      width: 100% !important;
    }
  }
}
