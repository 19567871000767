.loader-wrapper-V3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9) !important;
  z-index: 9999;
}

.loader-V3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spin-V3 {
  width: 45px;
  animation: spin-V3 2s linear infinite;

  .loader-message {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }
}

@keyframes spin-V3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
