.bi-portal-footer-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 77px;
}

.bi-portal-footer {
  margin-bottom: 10px;
}

.para-1 {
  margin-bottom: 0px !important;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin-top: 0px;
}

.para-2 {
  display: flex;
  justify-content: center;
  margin-top: 0px !important;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin-bottom: 0px;
}
