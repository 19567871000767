.activity-wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 7px auto;
  padding: 10px;
  .activity-header {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .black {
    color: #1d1d1f;
  }
  .white {
    color: #ffffff;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;
    justify-content: space-between;
  }
  .activity-card {
    padding: 7px 10px;
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    .content-div {
      flex-grow: 1;
    }
    .action-div {
      height: 30px;
    }
    span {
      font-size: 12px;
    }
    .action {
      display: block;
      font-size: 14px !important;
      svg {
        margin-left: 10px;
      }
    }
  }
  .bg-green {
    background-color: rgba(42, 92, 93, 1);
  }
  .bg-yellow {
    background-color: rgba(248, 209, 133, 1);
  }
  .bg-light-pink {
    background-color: rgba(228, 186, 183, 1);
  }
  .bg-blue {
    background-color: rgba(16, 56, 154, 1);
  }
}
