.primary-PatientAvailabiity-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Home-navigation {
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Navigation {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      .Home-img {
        height: 25px;
      }
    }
  }
  .secondary-PatientAvailabiity-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;
    .Availabilty-Header {
      color: #122f4e;
      font-size: 34px;
      font-weight: 700;
      line-height: 42px;
      font-family: Arial, Helvetica, sans-serif !important;
    }
    .Location-img {
      height: 30px;
    }
    .primary-text {
      font-size: 14px;
      font-weight: 200;
      margin: 8px 0px;
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif !important;
    }
    .third-container {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 35px;

      .Radio-Button {
        display: flex;
        align-items: center;
        height: 35px;

        .Radio-input {
          color: #6987ca;
        }
      }

      .Third-text {
        display: flex;
        align-items: center;
        color: #122f4e;
        font-size: 14px;
        font-weight: 200;
        font-family: Arial, Helvetica, sans-serif !important;
      }
    }
  }
  .Button-container {
    display: flex;
    justify-content: center;

    .Next-button {
      background-color: #6987ca;
    }

    .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
      background-color: gray;
      color: white;
    }

    .css-1hw9j7s.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
}

@media screen and (max-width: 636px) {
  .secondary-PatientAvailabiity-container {
    padding: 0px 10px;
    .primary-text {
      font-size: 12px !important;
    }
  }
}
