.modal {
  position: absolute;
  z-index: 10;
  transform: translate(10%, -60%);
  top: 50%;
  left: 50%;
}

.modal-content {
  background: white;
  border-radius: 5px;
  position: relative;
  color: #122f4e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.appt-details {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.appt-details-heading {
  width: inherit;
  text-align: center;
  font-size: 22px;
}

.appt-details-heading h3 {
  margin: 20px 0 0;
}

.appt-details img {
  margin-right: 8px;
  width: 22px;
}

.address {
  width: 115px;
}

.address-link {
  color: rgba(74, 112, 198, 1);
}

.maps-address{
  text-decoration: none;
  color: rgba(74, 112, 198, 1);;
}

.appt-details-div {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  word-wrap: break-word;
}

@media (max-width: 5670px) {
  .modal-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    z-index: 10;
  }

  .modal-body {
    margin-left: 16px;
  }

  .modal-content-mobile {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    background-color: #fbfcff;
    overflow-y: auto;
    padding-top: 76px;
    justify-content: start;
    align-items: flex-start;
  }

  .mobile-home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .back-button,
  .home-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #122f4e;
  }

  .modal-content > .header {
    position: fixed;
    top: 0;
    justify-content: space-between;
    width: 100%;
  }

  .mobile-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .appt-details {
    font-size: 12px;
  }

  .modal-body h4 {
    font-size: 18px;
    margin-bottom: 6px;
  }
}
