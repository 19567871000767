.primary-PatientAvailabilityStart-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Location-navigation {
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Navigation {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      .Home-img {
        height: 25px;
      }
    }
  }
  .secondary-PatientAvailabilitystart-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;
    padding: 5px 0px;

    .primary-text {
      font-size: 14px;
      font-weight: 200;
      margin: 8px 0px;
      color: #122f4e;
      padding: 5px 0px;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .primary-date {
      padding: 20px 0px;
    }

    .Day-img {
      width: 315px;
      padding: 5px 0px;
    }
  }

  .third-PatientAvailabilityDay-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;

    .third-text {
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }

  .Button-container {
    display: flex;
    justify-content: center;

    .Next-button {
      background-color: #6987ca;
    }

    .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
      background-color: gray;
      color: white;
    }

    .css-1hw9j7s.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
}

@media screen and (max-width: 636px) {
  .primary-PatientAvailabilityStart-container {
    padding: 0px 10px;
  }

  .secondary-PatientAvailabilitystart-container {
    .primary-text {
      padding: 5px 10px !important;
      font-size: 12px !important;
    }

    .Start-img {
      padding: 0px 0px 0px 10px;
    }

    .primary-date {
      padding-left: 10px !important;
    }
  }
}
