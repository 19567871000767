.w-100 {
  width: 100%;
}
.h-center {
  display: flex;
  justify-content: center;
}
.fd-column {
  display: flex;
  flex-direction: column;
}
.v-center {
  display: flex;
  align-items: center;
}
.txt-center {
  text-align: center;
}
.mr-50 {
  margin-top: 50px;
}
.mr-20 {
  margin-top: 20px;
}

.primary-wrapper {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  justify-content: space-between; 
}

.confirm-day-wrapper {
  width: 100%;
  max-width: 768px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.confirm-new {
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: fit-content;
    padding: 9px;
    color: rgba(16, 56, 154, 1);
    background-color: transparent;
    border: 1px solid rgba(16, 56, 154, 1);
    border-radius: 6px;
    cursor: pointer;
  }
  button:hover {
    background-color: rgba(16, 56, 154, 1);
    color: #ffffff;
  }
  button:disabled {
    border-color: rgba(128, 128, 128, 1) !important;
    color: rgba(128, 128, 128, 1) !important;
    background-color: tansparent !important;
  }
  button:disabled:hover {
    border-color: rgba(128, 128, 128, 1) !important;
    color: rgba(128, 128, 128, 1) !important;
    background: none !important;
  }
}
