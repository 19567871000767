.logout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafcff;
  height: 100vh;
}
.logout-text-container {
  text-align: center;
  max-width: 500px;
}
.logout-heading, .logout-body, .logout-container > :first-child {
  color: #122f4e;
}
.btn-primary {
  background-color: #337ab7;
  border: none;
  color: #fff;
  width: 374px;
  height: 31px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .logout-container img {
    width: 350px;
  }
  .logout-text-container {
    padding: 0 15px;
  }
}