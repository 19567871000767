.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  text-align: center;
  position: relative;
  min-height: 220px;
}

.pop-up-close-button {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  color: #10389a;
  font-weight: 400;
}

.heading {
  color: #b60000;
  font-weight: 700;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  margin-bottom: 0px;
  padding: 10px 25px 5px;
  height: 50px;
}

.paragraph {
  color: #122f4e;
  font-size: 12px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  padding: 0px 10px 0px 10px;
  height: 80px;
}

.pop-up-submit-button {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  color: #10389a;
  font-weight: 600;
}

.close-button {
  border-bottom: 1px #e8e8e8 solid;
}
