.client-cancel-bar-container {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 8px -2px rgba(21, 21, 21, 0.08),
    0px 6px 12px -2px rgba(21, 21, 21, 0.08);
  margin: 20px 0px;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .client-cancel-bar-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .client-cancel-bar-text {
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 500;
      padding: 5px;
    }
  }

  .client-cancel-bar-bottom-container {
    display: flex;
    align-items: center;
    .client-cancel-bar-go-btn {
      color: #4a70c6;
      cursor: pointer;
      padding: 0px 5px;
      font-size: 16px;
      background-color: white;
      border: none;
      box-shadow: none;
    }

    .ant-btn-primary:disabled {
      background: rgba(0, 0, 0, 0.04) !important;
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: white !important;
      border: none !important;
    }
    .ant-btn-primary:hover {
      box-shadow: none;
      color: #4a70c6;
      background-color: white;
    }
  }
}
