.client-cancel-details-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 20px;

  .client-cancel-inner-top-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .client-cancel-mobile-reschedule-box-container {
      border-radius: 6px;
      background: #edf5fd;
      box-shadow: 0px 1px 2px 0px rgba(21, 21, 21, 0.08),
        0px 2px 4px 0px rgba(21, 21, 21, 0.08);
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .client-cancel-mobile-txt {
        color: #12467b;
        font-size: 16px;
        font-weight: 700;
        font-family: Arial, Helvetica, sans-serif;
      }
      .client-cancel-mobile-txt-question {
        color: #12467b;
        font-size: 14px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
      }

      .client-cancel-mobile-divider {
        width: 100%;
        display: flex;
        color: #c7dff7 !important;
      }

      .client-cancel-mobile-reschedule-box-bottom-container {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        gap: 30px;
        .client-cancel-mobile-bottom-txt {
          font-size: 14px;
          color: #12467b;
          font-weight: 700;

          .client-cancel-mobile-reschedule-number-txt {
            display: contents;
            width: 16px;
          }

          .client-cancel-mobile-number {
            font-size: 14px;
            color: #12467b;
          }

          .client-cancel-email {
            font-size: 14px;
            color: #12467b;
          }
        }
        .client-cancel-mobile-btn {
          font-size: 14px;
          border-color: #0b6bcb !important;
          background-color: aliceblue;
          color: #0b6bcb;
        }
      }
    }

    .client-cancel-mobile-no-rescheduling-options-box-container {
      border-radius: 6px;
      background: #edf5fd;
      box-shadow: 0px 1px 2px 0px rgba(21, 21, 21, 0.08),
        0px 2px 4px 0px rgba(21, 21, 21, 0.08);
      padding: 10px;
      display: flex;
      flex-direction: column;
      .client-cancel-mobile-txt {
        color: #12467b;
        font-size: 16px;
        font-weight: 700;
        font-family: Arial, Helvetica, sans-serif;
      }
      .client-cancel-mobile-txt-question {
        color: #12467b;
        font-size: 14px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
      }

      .client-cancel-mobile-divider {
        width: 100%;
        display: flex;
        color: #c7dff7 !important;
      }

      .client-cancel-mobile-reschedule-box-bottom-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        .client-cancel-mobile-bottom-txt {
          font-size: 14px;
          color: #12467b;
          font-weight: 500;
          line-height: 1.5;

          .client-cancel-mobile-reschedule-number-txt {
            display: contents;
            width: 16px;
          }

          .client-cancel-mobile-number {
            font-size: 14px;
            color: #12467b;
          }

          .client-cancel-email {
            font-size: 14px;
            color: #12467b;
          }
        }
        .client-cancel-mobile-btn {
          font-size: 14px;
          border-color: #0b6bcb !important;
          background-color: aliceblue;
          color: #0b6bcb;
        }
      }
    }
    .cancel-details-heading {
      color: #122f4e;
      font-size: 24px;
      font-weight: 700;
      font-style: normal;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .cancel-details-paragraph {
      color: #122f4e;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-family: Arial, Helvetica, sans-serif !important;
      .client-details-paragraph-txt {
        font-size: 14px;
        color: #32383e;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 600;
      }
    }

    .client-details-container {
      display: flex;
      width: 318px;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      border-radius: var(--radius-sm, 6px);
      background: var(--common-white, #fff);
      box-shadow: 0px 2px 8px -2px rgba(21, 21, 21, 0.08),
        0px 6px 12px -2px rgba(21, 21, 21, 0.08);

      .client-details-inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .client-details-inner {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 250px;
          color: #122f4e;
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif !important;
          padding-left: 10px;
        }
      }
    }
    // .client-cancel-mobile-reschedule-box-container {
    //   display: none;
    // }
  }

  .cancel-details-buttons {
    width: 50%;
    gap: 20px;
    display: flex;
    justify-content: space-between;

    .button-all {
      width: 200px;
    }
    .button-next {
      background: #4a70c6;
    }
    .ant-btn-primary:hover {
      background: #4a70c6 !important;
    }
  }

  .cancel-late-tag {
    color: blue !important;
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width: 820px) {
    .client-cancel-inner-top-container {
      .cancel-details-inner-heading {
        display: none !important;
      }

      .client-cancel-mobile-reschedule-box-container {
        border-radius: 6px;
        background: #edf5fd;
        box-shadow: 0px 1px 2px 0px rgba(21, 21, 21, 0.08),
          0px 2px 4px 0px rgba(21, 21, 21, 0.08);
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .client-cancel-mobile-txt {
          color: #12467b;
          font-size: 16px;
          font-weight: 700;
          font-family: Arial, Helvetica, sans-serif;
        }
        .client-cancel-mobile-txt-question {
          color: #12467b;
          font-size: 14px;
          font-weight: 400;
          font-family: Arial, Helvetica, sans-serif;
        }

        .client-cancel-mobile-divider {
          width: 100%;
          display: flex;
          color: #c7dff7 !important;
        }

        .client-cancel-mobile-reschedule-box-bottom-container {
          display: flex;
          flex-direction: row;
          // justify-content: space-between;
          align-items: center;
          gap: 30px;
          .client-cancel-mobile-bottom-txt {
            font-size: 14px;
            color: #12467b;
            font-weight: 700;

            .client-cancel-mobile-reschedule-number-txt {
              display: contents;
              width: 16px;
            }

            .client-cancel-mobile-number {
              font-size: 14px;
              color: #12467b;
            }

            .client-cancel-email {
              font-size: 14px;
              color: #12467b;
            }
          }
          .client-cancel-mobile-btn {
            font-size: 14px;
            border-color: #0b6bcb !important;
            background-color: aliceblue;
            color: #0b6bcb;
          }
        }
      }

      .client-details-container {
        width: 100% !important;

        .client-details-inner {
          white-space: unset !important;
          overflow: unset !important;
          text-overflow: unset !important;
          width: 100% !important;
        }
      }
    }
    .cancel-details-buttons {
      width: 100% !important;
      margin: 10px 0px !important;
      .button-all {
        width: 100% !important;
      }
    }

    .client-details-client-bar {
      display: none !important;
    }
  }
}
