.custom-menu {
  min-height: 10px;
  box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.086);
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 5px;

  .last {
    border: none !important;
  }
  .menu-option {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    padding: 10px 20px;
    cursor: pointer;
    span {
      font-size: 16px;
      color: rgba(18, 47, 78, 1);
    }

    .menu-pointer-observer{
      color: grey;
      cursor: not-allowed;

      .svg{
        height: 10px;
        width: 10px;
      }
    }

    .menu-info{
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .disabled-option {
    span {
      font-size: 16px;
      color: #808080;
      cursor: not-allowed;
    }
    pointer-events: none; /* Disable pointer events */
  }

  .conv-span {
    span {
      animation: color-change 1s infinite;
      font-weight: 500;
      text-align: center;
    }

    @keyframes color-change {
      0% {
        color: red;
      }
      50% {
        color: black;
      }
      100% {
        color: red;
      }
    }
  }
}  

.appt-row {
  display: flex;

  .appt-row {
    display: flex;
  }
}