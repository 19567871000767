.dialogue-reload-redirect-container {
  max-width: 335px;
  padding: 30px;
  .dialogue-X-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px;
    cursor: pointer;
  }
  .dialogue-Heading {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #122f4e;
    font-size: 20px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 0px;
  }
  .button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Warning-button {
      background-color: #6987ca;
      box-shadow: none;
      margin: 5px 25px;
      color: white;
    }
    :hover {
      color: white;
      background-color: #6987ca !important;
    }
  }
}
