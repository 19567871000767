.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fafcff !important;
  padding-left: 5px;
  padding-right: 5px;

  .sticky-header {
    height: 70px;
    width: 100%; }

  .body-content {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    font-family: sans-serif; }

  .footer {
    flex: 0 0 auto;
    margin-top: auto; }

  .global-content-wish-text {
    color: rgba(18, 47, 78, 1);
    font-size: 26px !important;
    margin-bottom: 18px;
    display: block;
    font-weight: 500 !important; }


  .global-content-header {
    color: rgba(18, 47, 78, 1);
    font-size: 20px !important;
    margin-bottom: 18px;
    display: block;
    font-weight: 500 !important; }

  @media only screen and (max-width: 600px) {

    .global-content-wish-text {
      font-size: 20px !important; }

    .global-content-header {
      font-size: 18px !important; } } }
