.dialogue-Exit {
  .dialogue-container {
    max-width: 350px;
    padding: 30px;
    .dialogue-X-img {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px 0px;
      cursor: pointer;
    }
    .dialogue-Heading {
      display: flex;
      justify-content: center;
      color: #122f4e;
      font-size: 20px;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif;
      padding: 10px 15px;
      text-align: left;
    }
    .dialogue-paragraph {
      font-size: 16px;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
      padding: 10px 15px;
      text-align: left;
      .dialogue-anchor-tag {
        text-decoration: none;
        color: black;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .Warning-button {
        background-color: #b60000;
        box-shadow: none;
        margin: 5px 25px;
        text-transform: capitalize;
      }
      .Home {
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 300;
        cursor: pointer;
        color: #122f4e;
        height: 25px;
        align-items: center;
      }
    }
  }
}
