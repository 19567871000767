.primary-PatientAvailabilityReview-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Location-navigation {
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Navigation {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      .Home-img {
        height: 25px;
      }
    }
  }
  .secondary-PatientAvailabilityReview-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;

    .primary-text {
      font-size: 34px;
      font-weight: 700;
      margin: 8px 0px;
      color: #122f4e;
      padding: 10px 0px;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .Day-img {
      width: 315px;
    }
  }

  .Third-PatientAvailabilityReview-container {
    max-width: 475px;
    margin: auto;

    .secondary-text {
      color: #122f4e;
    }

    .Prompt {
      display: flex;
      background-color: #ffebca;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 10px 20px 0px #0000001c;
      border: 1px solid yellow;
      margin-bottom: 10px;

      .Warning-img {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  .Button-container {
    display: flex;
    justify-content: center;
    height: 100px;
    padding: 30px;

    .Next-button {
      background-color: #6987ca;
    }
  }
}

@media screen and (max-width: 636px) {
  .primary-PatientAvailabilityReview-container {
    padding: 0px 10px;
  }
}
