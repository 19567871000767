.primary-wrapper-provider .provider-calender-view {

  max-width: 760px;
  .status-bar {
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 37px;
    background: #ffffff;
    border: 1px solid black;

    @keyframes color-change {
      0% {
        color: red !important;
      }
      50% {
        color: black !important;
      }
      100% {
        color: red !important;
      }
    }
  }

    .page-header {
      width: 100%;
      padding: 0px 15px;
      .page-header-h2 {
        font-weight: 500 !important;
        color: rgba(18, 47, 78, 1);
        margin: 5px;
      }
    }

  .fc .fc-button:focus,
  .fc .fc-button-primary:focus,
  .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    max-width: 1920px;
    height: 1025px;
    padding: 25px 150px;
  }
  .fc .fc-toolbar-title {
    font-size: 1.5em;
    color: #122f4e;
    margin-top: 20px;
  }
  .fc .fc-toolbar {
    justify-content: center;
  }

  .fc-prev-button:active,
  .fc-next-button:active {
    background-color: #fff !important;
    border: none !important;
    background-image: none !important;
  }
  .fc .fc-button-primary.fc-today-button:active {
    background-color: #ffffff !important;
    color: rgba(16, 56, 154, 1) !important;
    border: 1px solid rgba(16, 56, 154, 1) !important;
  }
  .fc .fc-button-primary {
    color: #122f4e;
    border: none;
    background-color: #fff;

    &:hover {
      background-color: #fff;
      color: #4b70c6;
    }
  }
  .fc .fc-button-primary.fc-today-button {
    color: #fff;
    background-color: #ffffff !important;
    color: #10389a !important;
    border: 1px solid #10389a !important;
    text-transform: capitalize;
    padding: 0;
    width: 110px;
    margin: 0;
    margin-top: 15px;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    border: 1px solid #7e7e7e;
    overflow: hidden;
  }

  .fc-v-event.blue-event {
    background-color: #e1efff !important;
  }

  .appt-title {
    display: flex;
    justify-content: center;
  }

  .appt-location-one-day {
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .blue-event-one-day {
    background-color: #e1efff !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    height: 160px;
    width: 140px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }

  // .fc-v-event.active-event {
  //   box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.18);
  //   transition: box-shadow 0.1s ease-in-out;
  //   background-color: #e1efff !important;
  //   transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  // }

  // .active-event {
  //   box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.18);
  //   transition: box-shadow 0.1s ease-in-out;
  //   background-color: #e1efff !important;
  //   transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  // }

  .fc-v-event.inactive-event {
    background-color: #e1efff !important;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  }

  .fc-v-event.yellow-event {
    background-color: #ffebca !important;
  }

  .yellow-event-one-day {
    background-color: #ffebca !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    height: 160px;
    width: 140px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }
  .fc-event-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fc .fc-timegrid-slot-minor {
    border-top-style: none;
  }
  .appt-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    pointer-events: none;
  }
  .appt-provider,
  .appt-location,
  .appt-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
  .appt-type {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
  }
  .appt-location,
  .appt-provider {
    font-size: 12px;
  }
  .color-dot {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid #7e7e7e;
  }
  .patient-color-key {
    display: flex;
    justify-content: space-evenly;
    margin: 0px 16px 24px;
  }
  .patient-color-item {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .calender-view .fc-toolbar-chunk > .fc-button-primary:focus {
    box-shadow: none !important;
  }

  .calender-view .fc-button-primary {
    cursor: pointer;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 8px 1px 0px 0px;
  }

  .dropdown-option {
    background-color: white;
    color: black;
  }

  .appointment-list-provider {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: 25px 5px;
    max-width: 850px;
  }

  .appt-card-oneDay {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    background-color: rgba(225, 239, 255, 1);
    height: 150px;
    width: 130px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }

  .appt-details-one-day {
    font-weight: 100;
  }

  .special-class .fc-view-harness {
    display: none;
  }

  .special-class .fc .fc-toolbar.fc-header-toolbar {
    margin: 0;
  }

  .special-class .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    max-width: 1920px;
    height: 100px;
    padding-top: 10px !important;
  }

  .dayAppointments {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .dayAppointments-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 4rem;
    border: 1px double;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
  }

  .dayAppointments-1.active {
    background-color: rgba(16, 56, 154, 1);
    color: white;
    box-shadow: 0px 0px 7px 6px rgba(36, 32, 32, 0.14);
    pointer-events: none;
  }

  .dayAppointments-2,
  .dayAppointments-3 {
    display: flex;
    justify-content: center;
  }

  .dayAppointments-group {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  }

  .no-appointments {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .no-appointments-1 {
    margin: 0px 0px 35px 0px;
    font-size: 24px;
    font-weight: 500;
  }

  .empty-div-blue-event {
    display: none;
  }

  .empty-div-yellow-event {
    display: none;
  }

  .appt-provider {
    display: flex;
    justify-content: center;
  }

  .appt-comma {
    display: none;
  }

  .appt-details-one-day-1 {
    pointer-events: none;
  }
  .appt-no {
    display: none;
  }

  .one-day-view-content {
    display: flex;
  }

  .fc .fc-view-harness {
    margin-top: 50px;
  }

  .appointment-wrapper {
    width: 100%;
    display: flex;
    height: auto;
    margin: 8px 0px;
    border-radius: 5px;
    color: rgba(18, 47, 78, 1);
    font-size: 14px;
    border: 1px solid rgba(196, 196, 196, 1);
    overflow: hidden;
    .color-blue {
      background-color: rgba(16, 56, 154, 1);
      width: 10px;
    }
    .appointment-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px;
      .appt-row {
        width: 100%;
        text-overflow: ellipsis;
        padding: 5px 4px;

        .appt-observer-eye-img{
          height: 100%;
        }
      }
    }

    .menu-wrapper-abs {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-right: 2rem;
    }

    .custom-menu {
      min-height: 10px;
      box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.086);
      background-color: #fff;
      border-radius: 6px;
      position: absolute;
      padding: 3px 5px;

      .last {
        border: none !important;
      }
      .menu-option {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d3d3d3;
        padding: 10px 3px;
        span {
          font-size: 16px;
          color: rgba(18, 47, 78, 1);
        }
      }
    }
    .menu-pointer {
      cursor: pointer;
    }

    .edit-here {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        font-size: 20px;
        color: rgba(16, 56, 154, 1);
        border: none;
        margin: 3px 20px 3px 5px;
        background: transparent;
        padding: 3px 8px;
        border-radius: 4px;
      }
      button:hover {
        background-color: rgba(16, 56, 154, 1);
        color: #ffffff;
        cursor: pointer;
      }
      button:disabled {
        // background-color: #d3d3d3;
        color: #808080;
        cursor: not-allowed;
      }
      button:disabled:hover {
        background-color: #d3d3d3;
      }
    }

    .edit-here-failed {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        font-size: 14px;
        color: black;
        border: 1px solid #b60000;
        margin: 3px 20px 3px 5px;
        background: transparent;
        padding: 3px 8px;
        border-radius: 4px;
      }
      button:hover {
        background-color: #b60000;
        color: #ffffff;
        cursor: pointer;
      }
      button:disabled {
        // background-color: #d3d3d3;
        color: #808080;
        cursor: not-allowed;
      }
      button:disabled:hover {
        background-color: #d3d3d3;
      }
    }
    .normal-error {
      p {
        font-size: 10px !important;
        color: red;
        font-weight: 500 !important;
        text-align: center !important;
      }
    }

    .sync-status {
      p {
        font-size: 11px !important;
        animation: color-change 1.3s infinite !important;
        font-weight: 900 !important;
        text-align: center !important;
      }

      @keyframes color-change {
        0% {
          color: red !important;
        }
        50% {
          color: black !important;
        }
        100% {
          color: red !important;
        }
      }
    }
  }

  .add-new {
    width: 100%;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    button {
      width: fit-content;
      padding: 9px;
      color: rgba(16, 56, 154, 1);
      background-color: transparent;
      border: 1px solid rgba(16, 56, 154, 1);
      border-radius: 6px;
    }
    button:hover {
      background-color: rgba(16, 56, 154, 1);
      color: #ffffff;
    }
    button:disabled {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background-color: tansparent !important;
    }
    button:disabled:hover {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: none !important;
    }
  }

  .add-new-button {
    cursor: pointer;
  }

  @media only screen and (max-width: 550px) {
    .loading-io {
      p {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .appt-comma-with-space {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 8px;
    }
    .fc .fc-toolbar-title {
      font-size: 1em;
    }
    .appt-card {
      font-size: 12px;
      text-align: center;
    }
    .patient-color-key {
      margin: 16px 42px;
    }
    .patient-color-item {
      margin: 0;
    }
    .color-dot {
      height: 22px;
    }
    .fc-timegrid-event .fc-event-main {
      padding: 1px 0px 4px 0px;
    }
    .appt-location,
    .appt-provider {
      font-size: 8px;
    }
    .fc-event-main {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fc-v-event.active-event {
      box-shadow: none;
    }

    .active-event {
      box-shadow: none;
    }

    .fc .fc-view-harness {
      margin-top: 50px;
    }

    .appt-comma-with-space {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .loading-io {
      p {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  //850 media query starts here

  // .load-io-top{
  //   margin-top: 50px;
  // }

  .loading-io {
    p {
      font-size: 24px;
      animation: color-change 1.2s infinite;
      font-weight: 500;
      text-align: center;
    }

    @keyframes color-change {
      0% {
        color: red;
      }
      50% {
        color: black;
      }
      100% {
        color: red;
      }
    }
  }

  .conv-span {
    span {
      animation: color-change 1s infinite;
      font-weight: 500;
      text-align: center;
    }

    @keyframes color-change {
      0% {
        color: red;
      }
      50% {
        color: black;
      }
      100% {
        color: red;
      }
    }
  }

  .msg-text {
    span {
      font-size: 12px !important;
    }
  }
  .warn-msg {
    span {
      color: red !important;
    }
  }

  .appt-location,
  .appt-provider {
    max-width: fit-content;
    font-size: 12px;
    overflow: unset;
    text-overflow: unset;
  }

  .appt-type {
    display: flex;
    justify-content: center;
    width: 95%;
  }

  .no-appointments-1 {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  .appt-provider {
    display: flex;
    justify-content: center;
  }
  .one-day-view-content {
    display: flex;
    padding: 0px 5px;
  }

  .dropdown-empty {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .yellow-event-one-day {
    width: 100%;
    height: 80px;
    margin: 10px 0px;
    align-items: flex-start;
    padding-left: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .blue-event-one-day {
    width: 100%;
    height: 80px;
    margin: 10px 0px;
    align-items: flex-start;
    padding-left: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .active-event {
    box-shadow: none;
  }

  .appt-details-one-day {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .appt-details-one-day-1 {
    display: flex;
  }

  .empty-div-blue-event {
    display: block;
    width: 10px;
    height: 79px;
    margin: 10px 0px;
    background-color: rgba(105, 135, 202, 1);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .empty-div-yellow-event {
    display: block;
    width: 10px;
    height: 79px;
    margin: 10px 0px;
    background-color: rgba(248, 209, 133, 1);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .greyed-text {
    color: rgba(128, 128, 128, 1) !important;
  }

  .appt-comma {
    display: block;
  }

  .appt-no {
    display: block;
    font-size: 18px;
    font-weight: 900;
    padding-left: 10px;
  }

  .dayAppointments {
    padding: 0px 20px;
    margin-top: 30px;
  }

  .dayAppointments-1 {
    height: 4rem;
    margin: 0px 2px;
  }

  .dayAppointments-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .dayAppointments-2,
  .dayAppointments-3 {
    font-size: 16px;
  }

  .dayAppointments-group {
    padding-top: 12px;
  }

  .fc-v-event.active-event {
    box-shadow: none;
  }

  .appt-comma-with-space {
    display: block;
    margin-right: 5px;
  }

  .one-day-appointment-div {
    display: contents;
  }

  .loader-V2 {
    position: inherit !important;
    margin-top: 75px !important;
    background-color: transparent !important;
  }
  .loader-wrapper-V2 {
    background-color: transparent !important;
  }
  //850 media query ends here

  .warning-red-alert {
    background-color: rgba(182, 0, 0, 1) !important;
  }
}
