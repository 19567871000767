.appointment-bar {
  width: 100%;
  max-width: 1024px;
  margin: 10px auto 0px auto;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(29, 29, 31, 0.5);
  color: rgba(18, 47, 78, 1);
  font-family: sans-serif;

  .fn-sm {
    font-size: 11px;
    font-weight: 100 !important;
  }

  .fn-md {
    font-size: 14px;
  }

  .fn-bold {
    font-weight: 600;
  }

  .stack-1 {
    width: 7px;
  }

  .warning-red-alert {
    background-color: rgba(182, 0, 0, 1) !important;
  }

  .stack-2 {
    width: 100px;
    border-right: 1px solid rgba(232, 232, 232, 1);
    margin: 3px 0px;
    padding: 10px 2px;

    div {
      padding: 3px 0px;
    }
  }

  .cur-pointer {
    cursor: pointer;
  }

  .stack-3 {
    padding: 4px 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .div-3 {
      margin-top: 3px;
    }
  }

  .stack-4 {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-left: auto;

    .dots-img {
      height: 30px;
      width: 80px;
    }
  }
  .downtime {
    cursor: not-allowed;
    pointer-events: none;
  }

  .uniform-font-size {
    font-size: 14px;
  }

  .address-information {
    span {
      display: inline;
      color: rgba(16, 56, 154, 1) !important;
    }

    a {
      color: rgba(74, 112, 198, 1) !important;
    }
  }

  .address-information {
    span {
      display: inline;
      color: rgba(16, 56, 154, 1) !important;
    }
  }

  @media (max-width: 750px) {
    .stack-3 {
      max-width: 300px;
    }
  }

  @media (max-width: 650px) {
    .stack-3 {
      max-width: 270px;
    }
  }

  @media (max-width: 460px) {
    .uniform-font-size {
      font-size: 11px;
    }

    .fn-md {
      font-size: 12px;
    }

    .stack-3 {
      max-width: 250px;
    }

    .stack-2 {
      width: 65px;
    }

    .stack-4 {
      .dots-img {
        width: auto;
      }
    }
  }

  @media (max-width: 367px) {
    .stack-3 {
      max-width: 200px;
    }
  }

  @media (max-width: 320px) {
    .stack-3 {
      max-width: 200px;
    }
  }
}
.failed {
  filter: opacity(0.6);
}

.popover-menu-clientAppointement-Bar {
  font-size: 14px;
  padding: 10px 10px;
  cursor: pointer;

  .disabled-option {
    color: #808080;
    // cursor: not-allowed;
    pointer-events: none;
    /* Disable pointer events */
  }
}

.popover-menu-clientAppointement-Bar:hover {
  color: #122f4e;
  background-color: #e1efff;
}
