.text-input-custom {
  .ant-col {
    width: 100% !important;
    max-width: none !important;
  }

  textarea {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100% !important;
    border: 1px solid #10389a !important;
    border-radius: 5px;
  }
}
