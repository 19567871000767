.client-home-wrapper {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 10px;
  background-color: rgba(250, 252, 255, 1);

  .year-and-month-calendar{
    display: flex;
    justify-content: center;
  }
  .year-and-month-calendar-context{
    color: black;
    font-weight: 700;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    width: 200px;
    text-align: center;
  }

  .see-all-appts {
    margin-top: 25px !important;
    color: #10389a !important;
    div {
      width: fit-content;
      padding: 3px 10px;
      cursor: pointer;
      border-radius: 5px;
    }
    div:hover {
      background-color: #e1efff;
    }
  }

  .fc-current-today {
    background-color: white;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid rgba(199, 223, 247, 1);
    cursor: pointer !important;
    color: rgba(11, 107, 203, 1);
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
    z-index: 50;
    margin-top: -64px;
  }

  .fc-current-today:disabled {
    background-color: white;
    width: 90px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid grey;
    cursor: pointer !important;
    color: grey;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
    z-index: 50;
    pointer-events: none;
    cursor: not-allowed;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(196, 196, 196, 1);
    width: 100%;
    height: 2px;
  }

  .txt-center {
    text-align: center;
  }

  .calender-view-desktop
    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    // max-width: 1920px;
    height: 1025px;
    padding: 25px 150px;
  }
  .fc .fc-toolbar-title {
    font-size: 1.5em;
    color: #122f4e;
  }
  .fc .fc-toolbar {
    justify-content: center;
  }

  .fc-prev-button:active,
  .fc-next-button:active {
    background-color: #fff !important;
    border: none !important;
    background-image: none !important;
  }

  .fc .fc-button-primary {
    color: #122f4e;
    border: none;
    background-color: #fff;

    &:hover {
      background-color: #fff;
      color: #4b70c6;
    }
  }
  .fc-today-button {
    display: none;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    border: 1px solid #7e7e7e;
    overflow: hidden;
  }

  .fc-v-event.blue-event {
    background-color: #e1efff !important;
  }

  .calender-view-desktop {
    height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .calender-view {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .appt-title-desktop {
    display: flex;
    justify-content: center;
    font-weight: bold;
  }

  .appt-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .appt-location-one-day {
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .blue-event-one-day-desktop {
    background-color: #e1efff !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    height: 160px;
    width: 140px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }

  .fc-v-event.active-event {
    box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.1s ease-in-out;
    // background-color: #e1efff !important;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  }

  .active-event {
    box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.1s ease-in-out;
    // background-color: #e1efff !important;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  }

  .fc-v-event.inactive-event {
    background-color: #e1efff !important;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  }

  .fc-v-event.yellow-event {
    background-color: #ffebca !important;
  }

  .yellow-event-one-day-desktop {
    background-color: #ffebca !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    height: 160px;
    width: 140px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }
  .fc-event-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fc .fc-timegrid-slot-minor {
    border-top-style: none;
  }
  .appt-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    pointer-events: none;
  }
  .appt-provider,
  .appt-location,
  .appt-type-desktop {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
  .appt-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
  }

  .appt-type-desktop {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
  }
  .appt-location,
  .appt-provider {
    font-size: 12px;
  }
  .color-dot {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid #7e7e7e;
  }
  .patient-color-key {
    display: flex;
    justify-content: space-evenly;
    margin: 25px 0px;
    font-size: 14px;
  }
  .patient-color-item {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .fc-toolbar {
    height: 72px !important;
  }

  .calender-view-desktop .fc-toolbar-chunk > .fc-button-primary:focus {
    box-shadow: none !important;
  }

  .calender-view .fc-toolbar-chunk > .fc-button-primary:focus {
    box-shadow: none !important;
  }

  .calender-view-desktop .fc-button-primary {
    cursor: pointer;
  }
  .calender-view .fc-button-primary {
    cursor: pointer;
  }
  .fc-timegrid-event .fc-event-main {
    padding: 8px 1px 0px 0px;
  }

  .appointment-list-desktop {
    margin: 35px 0px;
    display: flex;
    justify-content: center;
    height: 190px;
  }

  .appointment-list-desktop .css-ohwg9z {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Carousal-patient .css-ktslay-MuiButtonBase-root-MuiIconButton-root {
    color: black;
    opacity: 1;
    background-color: transparent;
  }

  .Carousal-patient .css-hn784z:hover button {
    background-color: transparent;
  }

  .Carousal-patient .css-1abc02a:hover button {
    background-color: transparent;
  }

  .Carousal-patient .css-e8vs8u {
    color: black;
    background-color: transparent;
    opacity: 1;
  }

  .appt-card-oneDay {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #122f4e;
    background-color: rgba(225, 239, 255, 1);
    height: 150px;
    width: 130px;
    justify-content: center;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
  }

  .appt-details-one-day {
    font-weight: 100;
  }

  .appt-details-one-day-1-desktop {
    pointer-events: none;
  }

  .special-class .fc-view-harness {
    display: none;
  }

  .special-class .fc .fc-toolbar.fc-header-toolbar {
    margin: 0;
  }

  .special-class .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: 115px;
  }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    padding: 0px;
  }

  .dayAppointements-parent {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .dayAppointements-parent-desktop {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
  }

  .dayAppointments {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    cursor: auto;
  }

  .dayAppointments-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 4rem;
    // border: 1px double;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
  }

  .dayAppointments-1.active {
    .dayAppointments-2 {
      color: rgba(16, 56, 154, 1) !important;
    }
    .dayAppointments-3 {
      background-color: rgba(225, 239, 255, 1);
      color: rgba(16, 56, 154, 1);
    }
    // background-color: rgba(74, 112, 198, 1);
    // color: white;
    // box-shadow: 0px 0px 7px 6px rgba(36, 32, 32, 0.14);
    pointer-events: none;
  }

  .dayAppointments-2,
  .dayAppointments-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: rgba(128, 128, 128, 1);
  }

  .dayAppointments-group {
    width: 100%;
    height: 100%;
    // padding-top: 20px;
  }

 

  .empty-div-blue-event {
    display: none;
  }

  .empty-div-yellow-event {
    display: none;
  }

  .appt-provider {
    display: flex;
    justify-content: center;
  }

  .appt-provider-desktop {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin: 0;
  }

  .appt-comma {
    display: none;
  }

  .appt-details-one-day-1 {
    pointer-events: none;
  }

  .appt-no-desktop {
    display: none;
  }

  .one-day-view-parent {
    display: flex;
    justify-content: center;
    gap: 71px;
  }

  .one-day-view-content {
    display: flex;
  }

  .fc .fc-view-harness {
    margin-top: 20px;
    height: 800px !important;
    margin-bottom: 25px;
  }

  .appt-comma-with-space-desktop {
    display: none;
  }
  .normal-class .fc-event:focus::after {
    background-color: transparent !important;
  }


  .empty-colour-event {
    display: none;
  }

  .appt-comma-with-space-desktop {
    display: none;
  }

  .appt-location,
  .appt-provider {
    max-width: fit-content;
    font-size: 12px;
    overflow: unset;
    text-overflow: unset;
  }

  .appt-type {
    display: flex;
    justify-content: center;
    width: 95%;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
    white-space: nowrap;
  }

  
  .spinner-calender-view {
    display: flex;
    justify-content: center;
    position: relative;
    .loader-V2 {
      background-color: transparent !important;
      position: relative !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 28px 0 20px;
      width: 100%;
    }
    .loader-wrapper-V2{
      background-color: transparent !important;
    }
    .spin-V2 {
      width: 55px;
    }
  }

  .no-appointments {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 70px 0px;
  }

  .no-appointments-1 {
    margin: 20px 0px;
    font-size: 20px;
    font-weight: 500;
  }

  .appt-provider {
    display: flex;
    justify-content: center;
  }
  .one-day-view-content {
    display: flex;
  }

  .dropdown {
    display: initial;
    position: relative;
    top: 130px;
    left: 0%;
  }
  .dropdown-class {
    display: none;
    z-index: 45;
    cursor: pointer;
    .icon-back {
      padding-top: 2px;
      height: 18px;
      width: auto;
    }
  }

  .dropdown-select {
    width: 80px;
    text-align: center;
    height: 25px;
    color: rgba(18, 47, 78, 1);
    border: none;
    cursor: pointer;
    font-size: 17px;
    text-align: left;
    padding-left: 6px;
    .icon-back {
      z-index: 55;
    }
  }

  .dropdown-select:active {
    outline: none;
    border: none;
  }
  .dropdown-select:focus {
    outline: 0;
  }

  .dropdown-option {
    background-color: white;
    color: black;
  }

  // .dropdown-empty {
  //   position: unset;
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  // }

  .appointment-list {
    .global-content-header {
      height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .global-day{
      font-size: large;
    }
    margin: 35px 0px;
    display: block;
  }
  .control-tool-bar {
    display: flex;
    height: 72px;
    margin-bottom: -70px;
    .view-picker {
      width: 150px;
      justify-content: center;
    }
    .other-controls {
      flex-grow: 1;
    }
  }
  .h-center {
    display: flex;
    justify-content: center;
  }
  .v-center {
    display: flex;
    align-items: center;
  }
  .flx-end {
    display: flex;
    justify-content: flex-end;
  }

  .yellow-event-one-day {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80px;
    align-items: flex-start;
    padding-left: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    overflow: auto;
    overflow-x: hidden;
  }

  .blue-event-one-day {
    background-color: #e1efff !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80px;
    margin: 10px 0px;
    align-items: flex-start;
    padding-left: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    overflow: auto;
    overflow-x: hidden;
  }

  .appt-details-one-day {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .appt-details-one-day-1 {
    display: flex;
  }

  .empty-div-blue-event {
    display: block;
    width: 10px;
    background-color: rgba(105, 135, 202, 1);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .empty-div-yellow-event {
    display: block;
    width: 10px;
    background-color: rgba(248, 209, 133, 1);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .appt-comma {
    display: block;
  }
  .smaller-font {
    font-size: 15px !important;
  }

  .block-header {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .dayAppointments-1 {
    height: 4rem;
    margin: 0px 2px;
    width: 3.5rem;
  }

  .dayAppointments-2,
  .dayAppointments-3 {
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  // .dayAppointments-group {
  //   // padding-top: 12px;
  // }

  .appt-comma-with-space {
    display: block;
    margin-right: 5px;
  }

  .one-day-appointment-div {
    display: contents;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 2px 2px !important;
    font-size: 12px !important;
  }
  .appointment-list {
    width: 100%;
    margin: 18px 0px;
  }

  // .special-class .fc-prev-button {
  //   margin-bottom: 20px;
  // }

  // .special-class .fc-next-button {
  //   margin-bottom: 20px;
  // }

  // .normal-class .fc-prev-button {
  //   margin-bottom: 20px;
  // }

  // .normal-class .fc-next-button {
  //   margin-bottom: 20px;
  // }

  // .hide-calendar {
  //   display: none;
  // }
  // .display-calendar{
  //   display: contents;
  // }
  .appt-details-one-day-desktop {
    display: flex;
    justify-content: center;
  }

  .loader-appointments {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  .custom-nav-control-button {
    padding: 0;
    font-size: 25px;
    font-weight: 100;
    // margin-left: 10px
    border: none;
    outline: none;
    color: rgba(74, 112, 198, 1);
    background-color: #ffffff;
    cursor: pointer;
    margin: 0px 3px;
  }
  // .fc-toolbar.fc-header-toolbar{
  //   display: none !important;
  // }
  .disable-cal-control {
    display: none !important;
  }

  @media screen and (max-width: 870px) {
    .calender-view-desktop
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 25px 50px !important;
    }
  }

  @media screen and (max-width: 650px) {
    .calender-view-desktop
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 25px 50px !important;
    }
  }

  @media screen and (max-width: 524px) {
    .calender-view-desktop
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 25px 20px !important;
    }
  }
  @media only screen and (max-width: 1368px) {
    .calender-view-desktop
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 25px 150px;
    }
    .appt-card {
      font-size: 12px;
      text-align: center;
      width: 100%;
    }
    .patient-color-key {
      margin: 25px 0px;
    }
    .patient-color-item {
      margin: 0;
    }
    .color-dot {
      height: 20px !important;
      width: 20px !important;
    }
    .fc-timegrid-event .fc-event-main {
      padding: 1px 0px 4px 0px;
    }
    .appt-location,
    .appt-provider {
      font-size: 12px;
    }
    .fc-event-main {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fc .fc-view-harness {
      margin-top: 20px;
      height: 800px !important;
    }
  }
  @media (max-width: 800px) {
    .control-tool-bar {
      margin-bottom: 0px;
      height: 28px;
    }
    .view-picker {
      justify-content: flex-start !important;
    }
    .fc-current-today {
      margin-top: 0px;
      margin-bottom: -60px;
    }
    .block-header {
      margin-bottom: 40px;
    }
    .dayAppointments {
      margin-bottom: 12px;
    }
  }

  @media (max-width: 650px) {
    .control-tool-bar {
      margin-bottom: 0px;
      height: 28px;
    }
    .fc-toolbar {
      height: 40px !important;
      margin-bottom: 0.5em !important;
    }
    .view-picker {
      justify-content: flex-start !important;
    }
  }

  @media (max-width: 480px) {
    .fc-toolbar-title {
      font-size: 1em;
    }
    .dayAppointments-2 {
      font-size: 12px;
    }
     .dayAppointments-3{
      font-size: 12px;
    }
  }

  @media (max-width: 280px) {
    .fc .fc-col-header-cell-cushion {
      padding: 2px 2px !important;
      font-size: 8px !important;
    }

    .fc .fc-toolbar-title {
      font-size: 1em;
    }

    .appointment-list {
      width: 100%;
      margin: 18px 0px;
    }

    .blue-event-one-day,
    .yellow-event-one-day {
      padding-left: 5px;
    }

    .appt-type {
      justify-content: flex-start;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      padding: 0px;
    }

    .dropdown {
      height: 20px;
    }
  }
}

@media(max-width: 600px) {
  .client-home-wrapper {
    padding: 5px;
  }
  .control-tool-bar {
    height: 0 !important;
  }
}