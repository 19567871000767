.header {
  display: flex;
  align-items: center;
  padding: 0 15px;

  .disable-click {
    pointer-events: none;
    pointer: disable; }

  .logo {
    margin: 16px auto;
    cursor: pointer;

    img {
      width: 171px; } }

  .info {
    background-color: #4B70C6;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer; }

  .sign-out {
    min-width: 110px; } }

@media only screen and (max-width: 600px) {
  .header {

    .logo {

      img {
        width: 141px; } } } }
