.para-sm {
  font-size: 14px;
}
.no-records {
  padding: 30px 10px;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.spinner-calender-view {
  display: flex;
  justify-content: center;
  position: relative;
  .loader-V2 {
    background-color: transparent !important;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px 0 20px;
    width: 100%;
  }
  .loader-wrapper-V2 {
    background-color: transparent !important;
  }
  .spin-V2 {
    width: 55px;
  }
}

.no-appointments {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 70px 0px;
}

.no-appointments-1 {
  margin: 20px 0px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 420px) {
  .para-sm {
    font-size: 12px;
  }
}
