.contact-main-container {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 10px;

  input {
    width: 100% !important;
    border: 1px solid #c4c4c8 !important;
    padding: 4px 7px;
    min-height: 32px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .ant-select-selector {
    border: 1px solid #c4c4c8 !important;
  }

  .text-input-custom textarea {
    border: 1px solid #c4c4c8 !important;
    width: 100% !important;
    max-width: none !important;
    padding: 5px;
  }
  .al-items-flx-end {
    margin-left: auto;
    padding-left: 5px;
  }

  .fluid-container {
    display: flex;
    width: 100%;

    .fluid-row {
      width: 50%;
      .form-row {
        max-width: 450px;
      }
    }
  }

  .submit-btn-form {
    width: 100%;
    height: 40px;
    background-color: #4a70c6;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .completed-submission {
    width: 100%;
    .error {
      color: #ff5733 !important;
    }
    .information-icons {
      display: flex;
      justify-content: center;
      padding: 25px 10px 15px 10px;
      svg {
        font-size: 40px;
        color: #4a70c6;
      }
    }
    .options-text {
      margin-top: 25px;
      text-align: center;
      button {
        outline: none;
        border: none;
        color: #4a70c6;
        font-size: 12px;
        cursor: pointer;
        background: transparent;
        text-decoration: underline;
      }
    }
  }
  .counter-text {
    color: #1d1d1f;
    padding-left: 2px;
    font-size: 12px;
  }
  .limit-exceeded {
    color: #ff5733; // Limit exceeded color
  }
  .information-text {
    background-color: #d8f4e2;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 25px;
    span {
      color: #0a470a;
      font-size: 14px;
      max-width: 300px;
    }
  }
  .it-error {
    background-color: #ffefee !important;
    span {
      color: #7d1212 !important;
    }
  }

  @media (max-width: 650px) {
    .information-text {
      padding: 6px;
    }
    .fluid-container {
      display: flex;
      flex-direction: column;
      .fluid-row {
        width: 100%;
        .form-row {
          max-width: 100%;
        }
      }
    }
    .al-items-flx-end {
      margin-right: auto;
      margin-left: 0;
      padding-left: 0px;
    }
  }
}
