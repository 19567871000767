.loader-wrapper-V2 {
  flex: 1;
  background-color: #f6f6f6;

  .loader-V2 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px 0 20px;
    width: 100%;
    top: 5px;

    .spin-V2 {
      width: 45px;
      @media (max-width: 540px) {
        width: 40px; }
      @media (max-width: 320px) {
        width: 35px; } } } }
