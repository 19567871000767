footer {
  background: linear-gradient(to right, #D8E0F3, #FAFCFF);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 110px 0px 100px;
  height: 75px;
  height: auto;
  flex: 0 0 50px;
  margin-top: auto;
  text-align: center;
  @media (max-width: 850px) {
    padding: 0px 50px 0px 50px; }


  .left-side, .right-side p {
    color: #122F4E; }

  .right-side {
    align-items: center;

    a {
      color: #4A70C6;
      font-size: 14px;
      margin: 0 8px;

      &:hover {
        opacity: .7; } }

    p {
      margin: 0;
      font-size: 14px; } } }

.reschedule-container {
  background-color: #fbfcff;
  color: #122F4E;
  padding: 0px 100px 0px 100px;
  width: 100%;
  display: flex;
  justify-content: center;

  p:last-child {
    font-weight: bold;
    font: helvecta; }

  h3 {
    font-weight: bold; }

  a {
      color: #122F4E;

      &:hover {
        opacity: .7; } } }

@media only screen and (max-width: 680px) {
  footer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px;

    .right-side {
      p {
        font-size: 12px; }
      a {
        margin: 0 8px;
        font-size: 12px; } }

    .left-side {
      p {
        font-size: 12px; } } }

  .reschedule-container {
    padding: 0px 0px 0px 12px;
    width: 96%; } }

@media only screen and (max-width: 285px) {

   footer .right-side {
    a {
      margin: 0 8px;
      font-size: 10px; }
    p {
      font-size: 10px; } } }
