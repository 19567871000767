.toggle-wrapper {
  cursor: pointer;

  .username {

    p {
      margin: 0;
      font-size: 15px; } } }

.sign-out {
  position: absolute;
  top: 54px;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #C9CFD3;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .user-info {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C9CFD3;
    text-wrap: nowrap;

    span {
      padding: 4px 15px;
      color: #21303A; } }


  span {
    text-decoration: none;
    color: #4B70C6;
    cursor: pointer;
    font-weight: 700;
    padding: 8px 15px 12px; } }




