.whole-wrapper {
  width: 100%;
}
.content {
  width: 100%;
  max-width: 600px;
  padding-top: 20px;
}
.add-new {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  button {
    width: fit-content;
    padding: 9px;
    color: rgba(16, 56, 154, 1);
    background-color: transparent;
    border: 1px solid rgba(16, 56, 154, 1);
    border-radius: 6px;
  }
  button:hover {
    background-color: rgba(16, 56, 154, 1);
    color: #ffffff;
  }
}
.mr-20 {
  margin-top: 20px;
}
.message-div {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
