.client-rescheduled-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 10px;
  .client-rescheduled-inner-container-top {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    .client-rescheduled-heading {
      color: #122f4e;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif !important;
      font-size: 24px;
      padding: 20px 0px;
    }

    .client-rescheduled-image {
      height: 250px;
    }
  }

  .client-rescheduled-inner-container-bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #122f4e;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 16px;
    margin: 15px 10px;

    .Client-rescheduled-telehealth-addresss {
      color: #0000ee;
    }

    .client-rescheduled-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .client-rescheduled-home {
        width: 40%;
        background-color: #4a70c6;
        color: white;
      }
    }
  }

  // @media screen and (max-width: px) {
  //   .client-rescheduled-inner-container-top {
  //     .client-rescheduled-image {
  //       height: 300px;
  //     }
  //   }

  //   .client-rescheduled-inner-container-bottom {
  //     justify-content: center;
  //     gap: 20px;
  //     height: auto;
  //   }

  //   .client-rescheduled-home {
  //     width: 100% !important;
  //   }
  // }
}

@media screen and (max-width: 1024px) {
  .client-rescheduled-container {
    justify-content: start;
    gap: 40px;
  }
}

@media screen and (max-width: 425px) {
  .client-rescheduled-inner-container-top {
    .client-rescheduled-image {
      height: 100% !important;
      width: 100%;
    }
    .client-rescheduled-heading {
      width: 100%;
      align-items: flex-start !important;
    }

    .client-rescheduled-inner-container-bottom {
      justify-content: center;
      gap: 20px;
      height: auto;
    }
  }

  .client-rescheduled-home {
    width: 100% !important;
  }
}
