.error-wrapper {
  background-color: #f6f6f6;

  .error {
    max-width: 500px;
    margin: 30px auto;
    border: 1px solid #dbdfe2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    padding: 15px 10px;

    .icon-wrapper {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background-color: #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;

      .calender-icon {
        color: #516673;
        font-size: 25px; } }

    .text-wrapper {
      p {
        color: #21303A;
        margin: 0;

        &:first-child {
          font-weight: 700;
          margin-top: 7px;
          font-size: 17px; }

        &:nth-child(2) {
          margin-top: 7px;
          font-weight: 400;
          font-size: 15px; } }

      button {
        margin-top: 24px;
        border: 2px solid #6F4BF1;
        border-radius: 8px;
        background-color: #fff;
        padding: 6px 26px;
        color: #6F4BF1;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer; }

      .error-steps {
        width: 100%;
        text-align: left;
        font-size: 14px;

        .txt-left {
          text-align: left !important; } } } } }
