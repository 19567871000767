.container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  //   height: 100%;
  justify-content: space-between;
  .Home-navigation {
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Navigation {
      display: flex;
      justify-content: flex-start;
      .Home-img {
        height: 25px;
      }
    }
  }

  .secondary-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;
    .Availabilty-Header {
      color: #122f4e;
      font-size: 34px;
      font-weight: 700;
      line-height: 42px;
      font-family: Arial, Helvetica, sans-serif !important;
    }
    .Primary-Text {
      font-size: 20px;
      font-weight: 400;
      margin: 14px 0px;
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}

@media screen and (max-width: 636px) {
  .container {
    .secondary-container {
      .Availabilty-Header {
        padding: 0px 10px;
      }
      .Primary-Text {
        padding: 0px 10px;
        font-size: 16px;
      }
    }
  }
}
