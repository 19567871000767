.carousel-container {
  position: relative;
  height: 135px; /* Adjust height as needed */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text horizontally */
}

.carousel-text {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth transition */
  text-align: center;
  padding: 0 10px;
  pointer-events: none; /* Disable pointer events by default */
}

.carousel-text a {
  margin: 0 5px; /* Adjust as needed */
  text-decoration: underline; /* Optional, to make links more visible */
  pointer-events: auto; /* Re-enable pointer events for links */
}

@keyframes carouselAnimation {
  0% {
    opacity: 0;
    pointer-events: none; /* Disable pointer events */
  }
  10% {
    opacity: 1;
    pointer-events: auto; /* Enable pointer events */
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
    pointer-events: none; /* Disable pointer events */
  }
  100% {
    opacity: 0;
  }
}

.carousel-text:nth-child(1) {
  animation: carouselAnimation 30s infinite;
}

.carousel-text:nth-child(2) {
  animation: carouselAnimation 30s infinite 10s; /* Starts after 10s */
}

.carousel-text:nth-child(3) {
  animation: carouselAnimation 30s infinite 20s; /* Starts after 20s */
}

.link-text {
  cursor: pointer;
  z-index: 45;
}

/* Rest of your styles */
.whole-container {
  display: flex;
  height: 100%;

  .split-screen {
    display: flex;
    width: 100%;

    .leftSide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      .login-screen {
        width: 100%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        .logo {
          width: 250px;
        }

        .login-screen {
          display: flex;
          flex-direction: column;
        }

        .welcome-subheading-login-password {
          font-size: 16px;
        }

        .welcome-heading {
          color: #122f4e;
          font-size: 24px;
        }

        .avg-margin-tp {
          margin-top: 10px;
        }

        .login-button {
          width: 100%;

          .btn-block {
            background-color: #337ab7;
            border: none;
            color: #fff;
            width: 100%;
          }

          .btn {
            padding: 6px 12px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            border-radius: 4px;
          }
        }
      }
    }

    .rightSide {
      background: linear-gradient(to bottom, #adbfe6, #dfe7f7, #fafcff);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;

      .right-wrapper {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;

        .right-side-img {
          width: 100%;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }

        p {
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.42857143;
          color: #333;
        }

        .right-side-txt {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .split-screen {
      flex-direction: column;
    }

    .leftSide {
      width: 100% !important;
      height: 50% !important;
    }

    .rightSide {
      width: 100% !important;
      height: 50% !important;
    }
  }
}
