.client-cancel-confirm-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  .client-cancel-confirm-loading-image {
    height: 250px;
    width: auto;
  }
  .client-cancel-confirm-loading-text {
    color: #000;
    font-family: "Helvetica" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    padding: 0px 20px;
  }
}

.client-cancel-review-main-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  justify-content: space-between;
  .Client-cancel-error-message-container {
    display: flex;
    justify-content: left;
    padding: 10px 0px;
    .Client-cancel-error-message {
      background-color: #ffefee;
      height: auto;
      display: flex;
      align-items: left;
      border-radius: 5px;
      padding: 0px 10px;
      text-align: center;
      gap: 10px;
      width: 100%;
    }
    .Client-cancel-error-message-1 {
      color: #7d1212;
      font-family: Helvetica;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
    }
  }
  .client-cancel-review-inner-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .client-cancel-review-inner-top-container {
      display: flex;
      gap: 20px;
      flex-direction: column;
      .client-cancel-review-text {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .client-cancel-review-heading {
          color: #122f4e;
          font-size: 24px;
          font-weight: 700;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .client-cancel-review-sub-text {
          color: #122f4e;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif !important;
        }
      }
      .client-cancel-review-box {
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 2px 8px -2px rgba(21, 21, 21, 0.08),
          0px 6px 12px -2px rgba(21, 21, 21, 0.08);
        padding: 10px;
        width: fit-content;
        .client-cancel-box-top {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .client-cancel-box-heading {
            color: #122f4e;
            font-size: 18px;
            font-weight: 700;
            font-style: normal;
            font-family: Arial, Helvetica, sans-serif !important;
          }
          .client-cancel-box-sub-heading {
            color: #122f4e;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-family: Arial, Helvetica, sans-serif !important;
          }
        }

        .client-cancel-box-bottom {
          padding: 10px 0px;
          .client-cancel-details-box-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            padding-top: 5px;
          }
          .client-cancel-details-inner-del {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 250px;
            color: #122f4e;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-family: Arial, Helvetica, sans-serif !important;
          }
        }
      }
    }
    .client-cancel-review-btn {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 50%;

      .button-all {
        width: 200px;
      }
      .button-next {
        background: #4a70c6;
      }

      .button-home {
        width: 100%;
        background: #4a70c6;
      }
    }
    .displayShow {
      display: block;
      visibility: visible;
    }

    .displayNone {
      display: none;
      visibility: hidden;
    }
  }

  @media screen and (max-width: 820px) {
    .client-cancel-review-inner-container {
      .client-cancel-review-inner-top-container {
        gap: 20px !important;
        height: 100% !important;

        .client-cancel-review-box {
          width: auto;
        }
      }

      .client-cancel-details-inner-del {
        white-space: unset !important;
      }
    }
    .client-cancel-review-btn {
      width: 100% !important;
      margin: 10px 0px !important;

      .button-all {
        width: 100% !important;
      }
      .button-home {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .client-cancel-review-main-container {
    min-height: 90%;
  }
}
