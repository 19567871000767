.Accordian-main {
  padding-bottom: 10px;
  margin: 5px auto;
  width: 100%;
  max-width: 1024px;
  padding: 10px;

  .activity-header {
    font-size: 18px;
    margin-bottom: 18px;
    display: block;
    font-weight: 600;
  }
  .Accordian-faq {
    border: 1px solid #00000038 !important;
    box-shadow: none !important;
  }
  .section-heading {
    font-size: 18px !important;
    font-weight: 500;
  }
  p {
    font-size: 14px;
  }
  .MuiAccordionSummary-root {
    border-bottom: 1px solid rgba(128, 128, 128, 1);
  }
  .MuiPaper-root {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media(max-width: 600px){
    .section-heading {
      font-size: 16px !important;
    }
    p {
      font-size: 14px;
    }
  }
}
.Accordian-main2 {
  padding-bottom: 10px;
  margin: 5px auto;
  width: 100%;
  max-width: 1024px;
  padding: 10px;

  .activity-header {
    font-size: 18px;
    margin-bottom: 18px;
    display: block;
    font-weight: 600;
  }
  .Accordian-faq {
    border: 1px solid #00000038 !important;
    box-shadow: none !important;
  }
  .section-heading {
    font-size: 18px !important;
    font-weight: 500;
  }
  p {
    font-size: 14px;
  }
  .MuiAccordionSummary-root {
    border-bottom: 1px solid rgba(128, 128, 128, 1);
  }
  .MuiPaper-root {
    padding-left: 5px;
    padding-right: 5px;
  }
  .cancel-late-tag {
    color: blue !important;
    text-decoration: underline;
    cursor: pointer;
  }
  @media(max-width: 600px){
    .section-heading {
      font-size: 16px !important;
    }
    p {
      font-size: 14px;
    }
  }
}