.primary-PatientAvailabilityClose-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: space-between;
  .secondary-PatientAvailabilityClose-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    .primary-text {
      font-size: 34px;
      font-weight: 700;
      margin: 8px 0px;
      color: #122f4e;
      font-family: Arial, Helvetica, sans-serif !important;
    }

    .Thank-you-img {
      display: flex;
      justify-content: center;

      .Thank-you {
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto;
      }
    }

    .Text {
      margin: 15px;
      height: 40%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .Inner-text {
        font-size: 16px;
        color: #122f4e;
        font-weight: 500;
        font-family: Arial, Helvetica, sans-serif !important;
      }
    }
  }

  .Button-container {
    display: flex;
    justify-content: center;
    padding: 30px;

    .Next-button {
      background-color: #6987ca;
    }
  }
}

@media screen and (max-width: 636px) {
  .primary-PatientAvailabilityClose-container {
    padding: 0px 10px;
  }
}
