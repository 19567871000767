.reset-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #fafcff;
}
.reset-body {
  text-align: center;
  color: #122f4e;
  margin: 6px;
}
.reset-body h1 {
  margin-bottom: 0;
}
