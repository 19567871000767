.subheader-container {
  padding: 0px 0px 0px 10px;
  height: 50px;
  background-color: rgba(250, 252, 255, 1);
  padding: 5px 0px 0px 5px;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;



  .subheader {
    max-width: 1115px;

    h2 {
      font-size: 28px;
      color: #21303A;
      font-weight: 700;
      margin: 20px 0 5px 0; }

    p {
      font-size: 15px;
      margin: 5px 0 20px 0;
      font-weight: 400;
      color: #21303A; } } }

.welcome-subheader {
  background: linear-gradient(to right, #D8E0F3, #FAFCFF);
  display: flex;
  align-items: center;
  padding: 0 100px;
  justify-content: space-between;

  p {
    font-size: 32px;
    color: #122f4e; }

  img {
    margin-right: 25px; }

  div {
    font-size: 18px;
    color: #122f4e;
    font-weight: 400; } }

.welcome-div-details-appointment {
  display: flex;
  align-items: center;
  padding: 16px 0px 0px 0px;
  justify-content: space-between;
  width: 92%; }

.welcome-div-details-contact {
  padding: 16px 0px 1px 0px; }

@media only screen and (max-width: 600px) {

  .global-content-wish-text {
    font-size: 20px !important; }
  .subheader-container {
    padding: 0px 0px 0px 12px;

    .subheader {
      padding: 0;
      height: 65px;
      display: flex;
      align-items: center;

      h2 {
        font-size: 26px; }

      div {
        font-size: 14px; } } }

  .welcome-subheader {
    padding: 0 12px;

    div {
      font-size: 12px;
      font-weight: 400; }

    img {
      width: 120px;
      margin: 0px 0px 0px 0px; } }

  .welcome-div-details-salutation {
    padding: 4px 0px 0px 0px; }

  .welcome-div-details-appointment {
    padding: 16px 0px 0px 0px; }

  .welcome-div-details-contact {
    padding: 16px 0px 4px 0px;
    width: 92%; } }

@media only screen and (max-width: 280px) {

  .welcome-subheader {
      padding: 0 12px;

      img {
        width: 100px;
        margin: 0px 0px 0px 0px; } } }
