.primary-PatientAvailabilityBuilderReview-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Navigation {
    cursor: pointer;
  }
  .secondary-PatientAvailabilityBuilder-container {
    max-width: 598px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;

    .primary-text {
      font-size: 34px;
      font-weight: 700;
      margin: 8px 0px;
      color: #122f4e;
    }

    .secondary-text {
      font-size: 18px;
      font-weight: 400;
      margin: 14px 0px;
      color: #122f4e;
    }

    .availability-Date {
      display: flex;
      justify-content: space-between;
      width: 55%;
    }

    .validate-text {
      display: flex;
      justify-content: center;
      color: #b60000;
    }
  }

  .Button-container {
    display: flex;
    justify-content: center;
    padding: 30px;

    .Next-button {
      background-color: #6987ca;
      color: white;
    }

    :hover {
      color: white !important;
      background-color: #6987ca !important;
    }
  }
}

.dialogue-reload-add-container {
  max-width: 335px;
  padding: 30px;
  .dialogue-X-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px;
    cursor: pointer;
  }
  .dialogue-Heading {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #122f4e;
    font-size: 20px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 0px;
  }
  .button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Warning-button {
      background-color: #6987ca;
      box-shadow: none;
      margin: 5px 25px;
      color: white;
    }
    :hover {
      color: white;
      background-color: #6987ca !important;
    }
  }
}

@media screen and (max-width: 636px) {
  .primary-PatientAvailabilityBuilderReview-container {
    padding: 0px 10px;
  }

  .primary-PatientAvailabilityBuilderReview-container {
    .secondary-PatientAvailabilityBuilder-container {
      .secondary-text {
        font-size: 16px;
      }
      .availability-Date {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;
      }
    }
  }
}
