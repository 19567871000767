.verifyemail-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;

  .verifyemail {
    width: 310px;
    height: auto;
    background-color: #fff;
    margin: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid #C9CFD3;
    border-radius: 3px;

    .img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110px;
      border-bottom: 1px solid #C9CFD3;
      padding: 10px; }

    img {
      width: 195px;
      height: 66px; }

    .text-wrapper {
      text-align: center;
      padding: 11px;

      p:nth-child(1) {
        font-weight: 700;
        font-size: 20px;
        color: #21303A; }

      p:nth-child(2) {
        font-size: 15px;
        font-weight: 400;
        color: #21303A;
        margin-bottom: 17px; }

      p:nth-child(3) {
        font-size: 14px;
        font-weight: 400;
        color: #415766;
        margin-bottom: 20px;

        button {
          font-size: 14px;
          font-weight: 700;
          color: #6F4BF1;
          text-decoration: none;
          background-color: #ffffff;
          border: 0;
          cursor: pointer;
          margin-bottom: 0; } }

      button {
        font-size: 16px;
        font-weight: 700;
        color: #6F4BF1;
        text-decoration: none;
        background-color: #ffffff;
        border: 0;
        cursor: pointer;
        margin-bottom: 15px; } } } }
