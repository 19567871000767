.client-cancel-appointment-main-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  justify-content: space-between;
  min-height: 100%;
  .client-cancel-appt-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .client-cancel-appt-inner-top-container {
      display: flex;
      gap: 20px;
      flex-direction: column;
      min-height: 42vh;
      .client-cancel-appt-text {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .client-cancel-appt-heading {
          color: #122f4e;
          font-size: 24px;
          font-weight: 700;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .client-cancel-appt-sub-text {
          color: #122f4e;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif !important;
        }
      }
      .client-cancel-appt-select-feild {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 40%;
        min-height: 24vh;
        margin-bottom: 10px;
      }
    }
    .client-cancel-appt-btn {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 50%;

      .button-all {
        width: 200px;
      }
      .button-next {
        background: #4a70c6;
      }
      .ant-btn-primary:disabled {
        background: rgba(0, 0, 0, 0.04) !important;
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
    .displayShow {
      display: block;
      visibility: visible;
    }

    .displayNone {
      display: none;
      visibility: hidden;
    }
  }

  @media screen and (max-width: 820px) and (orientation: portrait) {
    .client-cancel-appt-inner-container {
      gap: unset !important;
      .client-cancel-appt-inner-top-container {
        gap: 20px !important;
        height: 56vh;
      }

      .client-cancel-appt-select-feild {
        width: 100% !important;
      }
    }

    .client-cancel-appt-btn {
      width: 100% !important;
      margin: 10px 0px !important;

      .button-all {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 820px) and (orientation: landscape) {
    .client-cancel-appt-inner-container {
      gap: unset !important;
      .client-cancel-appt-inner-top-container {
        gap: 20px !important;
      }

      .client-cancel-appt-select-feild {
        width: 100% !important;
      }
    }

    .client-cancel-appt-btn {
      width: 100% !important;
      margin: 10px 0px !important;

      .button-all {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .client-cancel-appointment-main-container {
    min-height: 82vh;
    padding-bottom: 20px; /* Ensure padding at the bottom */
  }
}
