.Container {
  width: 390px;
  min-height: 82px;
  padding: 16px;
  border-radius: 10px;
  gap: 16px;
  box-shadow: 0px 1px 15px 0px #00000029;
  margin: 10px auto;

  .Heading {
    color: #4a70c6;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 16px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .Inner-main-container {
    border-bottom: 1px solid #00000026;

    .Inner-container {
      display: flex;
    }

    .Inner-text {
      height: 30px;
      display: flex;
      align-items: center;

      .Inner-text-Heading {
        font-size: 12px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        color: #122f4e;
        width: 58px;
      }

      .Inner-text-label {
        display: flex;
        width: 68%;
        justify-content: space-between;
      }
      .Inner-text-checkbox {
        font-size: 12px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        align-items: center;
        width: 100%;
      }
      .error-checkbox .ant-checkbox-inner {
        border: 1px solid red;
      }
    }

    .Inner-second-feild {
      display: flex;
      align-items: center;
      padding: 5px 0px;

      .Inner-text-startTime {
        font-size: 12px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        display: flex;
        align-items: center;

        .Inner-span-text {
          width: 75px;
        }

        .Inner-span-text-end {
          width: 75px;
          display: flex;
          justify-content: center;
          text-align: center;
        }

        .input-text {
          width: 70px;
        }
      }
    }

    .validation {
      min-height: 20px;
      font-size: 12px;
      color: #b60000;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .Trash-button-main {
    display: flex;
    justify-content: center;
    .Trash-button {
      cursor: pointer;
      padding: 15px 10px 35px 10px;
    }
  }

  .Trash-button-main-disable {
    display: flex;
    justify-content: center;
    .Trash-button {
      cursor: not-allowed;
      padding: 15px 10px 35px 10px;
    }
  }

  .Add-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 0px 10px;
    cursor: pointer;

    .Add-more-img {
      height: 10px;
    }

    .Add-more-text {
      font-size: 12px;
      font-weight: 400;
      color: #4a70c6;
    }
  }
}

@media screen and (max-width: 390px) {
  .Container {
    width: 355px;
  }
}

@media screen and (max-width: 360px) {
  .Container {
    width: 340px;
  }
}

@media screen and (max-width: 320px) {
  .Container {
    width: 285px;
  }
}
