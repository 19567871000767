.client-flow-main-container {
  .client-reschedule-spinner-sub-heading {
    height: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #122f4e;
    font-size: 16px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif !important;
  }
}
