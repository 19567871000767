.client-reschedule-main-container {
  height: 100%;
  .client-reschedule-container {
    max-width: 868px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    gap: 20px;

    .rescheduling-heading {
      color: #122f4e;
      font-size: 24px;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif !important;
      margin-bottom: 20px;
    }

    .empty-space {
      margin-bottom: 25px;
    }

    .block-header {
      display: block;
      font-size: 18px;
      font-weight: 900;
      margin: 20px 0px;
    }

    .remove-margin {
      margin: 0 !important;
    }

    .custom-back-nav {
      cursor: pointer;
      border: navajowhite;
      font-size: 20px;
      background: none;
      .icon-back {
        margin-right: 7px;
        font-size: 19px;
      }
      svg {
        padding-top: 2px;
      }
    }

    .rescheduling-paragraph {
      color: #122f4e;
      font-size: 16px;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif !important;

      .rescheduling-paragraph-bold {
        color: #122f4e;
        font-size: 16px;
        font-weight: 700;
        font-family: Arial, Helvetica, sans-serif !important;
      }
    }

    .reschedule-body {
      display: flex;
      flex-direction: row;
      min-height: 325px;
      .rescheduling-calender-left {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .reschedule-calender-style {
          width: 365px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          height: 320px;
        }

        .dropdown-1 {
          .ant-select-selector {
            border: none;
            background-color: #e1efff;
            color: #122f4e;
          }
        }

        .ant-select-selection-item {
          font-size: 15px;
          font-weight: 900;
        }
        .ant-picker-calendar
          .ant-picker-cell-in-view.ant-picker-cell-today
          .ant-picker-cell-inner::before {
          border-radius: 15px;
        }

        .ant-picker-calendar
          .ant-picker-cell-in-view.ant-picker-cell-selected:not(
            .ant-picker-cell-disabled
          )
          .ant-picker-cell-inner {
          background: #122f4e;
          border-radius: none;
        }

        .ant-picker-panel {
          border-top: none;
        }

        .ant-picker-date-panel
          .ant-picker-cell:not(.ant-picker-cell-in-view)
          .ant-picker-cell-inner {
          display: none;
        }

        .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
          pointer-events: none;
        }

        .ant-picker-calendar
          .ant-picker-cell-in-view.ant-picker-cell-today
          .ant-picker-cell-inner::before {
          border: 2px solid #1890ff; /* Adjust the color and width as needed */
          border-radius: 50%; /* Makes the border round */
        }

        tr {
          font-size: 18px;
        }

        th {
          font-weight: 700;
        }

        .ant-picker-cell {
          font-size: 14px;
          font-weight: 500;
        }

        .ant-picker-calendar .ant-picker-cell-disabled::before {
          background-color: white;
        }
      }

      .rescheduling-calender-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 50px;
        gap: 5px;

        ::-webkit-scrollbar {
          width: 5px; /* width of the entire scrollbar */
        }

        ::-webkit-scrollbar-thumb {
          background: #888; /* color of the handle */
          border-radius: 6px; /* roundness of the handle */
        }

        /* For scrollbar handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555; /* color of handle on hover */
        }

        .rescheduling-calender-right-top {
          gap: 12px;
          display: flex;
          flex-direction: column;

          .right-heading {
            color: #122f4e;
            font-weight: 500;
            font-size: 20px;
            font-family: Arial, Helvetica, sans-serif !important;
          }

          .right-time {
            display: flex;
            justify-content: left;
            gap: 4px 18px;
            max-height: 90px;
            overflow: auto;

            .right-time-inner {
              min-height: 40px;
              min-width: 110px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #c7dff7;
              border-radius: 5px;
              color: #12467b;
              font-weight: 600;
              cursor: pointer;

              .checkedImg {
                height: 20px;
              }

              .inner-timming {
                font-size: 12px;
              }
            }

            .clicked-time {
              background-color: #f0f2f3 !important;
              color: #9fa6ad !important;

              .checkedImg {
                height: 20px;
              }
            }
          }

          .right-inactive-days {
            font-size: 16px;
            font-weight: 900;
            color: #8080806e;
            padding: 40px 10px;
            height: 200px;
            display: flex;
            align-items: center;
            text-align: center;
          }

          .displayShow {
            display: block;
            visibility: visible;
          }

          .displayNone {
            display: none;
            visibility: hidden;
          }
        }

        .right-button {
          display: flex;
          justify-content: right;
          gap: 5px;
          margin-bottom: 5px;

          .button-next {
            background-color: #4a70c6;
            color: white;
          }
          .ant-btn-primary:disabled {
            background: rgba(0, 0, 0, 0.04) !important;
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }
    }

    .client-reschedule-spinner-sub-heading {
      height: 70%;
      display: flex;
      justify-content: center;
      color: #122f4e;
      font-size: 16px;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif !important;
    }
  }
}
.client-reschedule-dialogue-flow-reload-pop-up {
  max-width: 335px;
  padding: 30px;
  .client-dialogue-X-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px;
    cursor: pointer;
  }
  .client-dialogue-Heading {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #122f4e;
    font-size: 20px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 0px;
  }
  .client-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Warning-button {
      background-color: #6987ca;
      box-shadow: none;
      margin: 5px 25px;
      color: white;
    }
    :hover {
      color: white !important;
      background-color: #6987ca !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .client-reschedule-main-container {
    .client-reschedule-container {
      justify-content: start;

      .reschedule-body {
        margin: 10px;
        .rescheduling-calender-left {
          .reschedule-calender-style {
            width: 350px !important;
            height: auto;
          }
        }
        .rescheduling-calender-right {
          width: 70%;
          padding: 0px 10px;
          .right-time-inner {
            min-width: 28% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .client-reschedule-main-container {
    .client-reschedule-container {
      .reschedule-body {
        flex-direction: column;
      }

      .rescheduling-calender-left {
        flex-direction: unset !important;
        justify-content: center !important;

        .reschedule-calender-style {
          width: 100%;
          height: auto;
        }
      }

      .rescheduling-calender-right {
        width: 100% !important;
        margin: 25px 0px;
        gap: 20px !important;

        .rescheduling-calender-right-top {
          gap: 35px !important;
          .right-time {
            max-height: 85px;
          }
        }

        .right-button {
          margin-bottom: 40px;
          .button-1 {
            width: 60%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
