* {
  box-sizing: border-box;
  font-family: sans-serif; }

html {
  height: 100%;

  body {
    margin: 0;
    padding: 0;
    height: 100%;

    #root {
      height: 100%; } } }
