.loader-wrapper {
  flex: 1;
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px 30px 0px;
    padding: 0 28px 0 20px; }

  .spin {
      width: 45px; } }

