.client-reschedule-confirm-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  .client-reschedule-confirm-loading-image {
    height: 250px;
    width: auto;
  }
  .client-reschedule-confirm-loading-text {
    color: #000;
    font-family: "Helvetica" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    padding: 0px 20px;
  }
}

.client-reschedule-confirm-container {
  max-width: 868px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 40px;
  .client-reschedule-confirm-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
    .client-reschedule-confirm-heading {
      color: #122f4e;
      font-weight: 700;
      font-family: Arial, Helvetica, sans-serif !important;
      font-size: 24px;
    }
    .client-reschedule-confirm-paragraph {
      color: #122f4e;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif !important;
      font-size: 16px;
    }
  }
  .client-reschedule-confirm-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .client-reschedule-confirm-box {
      height: 250px;
      width: 550px;
      box-shadow: 0px 6px 12px -2px #15151514;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .client-reschedule-box-heading {
        color: hsl(211, 63%, 19%);
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 16px;
        padding-bottom: 15px;
      }
      .client-reschedule-box-sub-heading {
        color: #122f4e;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 14px;
      }

      .client-reschedule-inner-box {
        height: 100%;
        display: flex;
        .client-reschedule-inner-box-left {
          width: 50%;
          padding: 10px;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;

          .client-reschedule-inner-left-div {
            display: flex;
            align-items: center;
            .client-reschedule-inner-left-img {
              height: 20px;
            }
            .client-reschedule-inner-left-del {
              color: #122f4e;
              font-weight: 500;
              font-family: Arial, Helvetica, sans-serif !important;
              font-size: 12px;
              padding: 0px 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .client-reschedule-ineer-box-divider {
          margin-top: 25px;
          margin-bottom: 25px;
        }
        .client-reschedule-inner-box-right {
          width: 50%;
          padding: 10px 10px 10px 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .client-reschedule-inner-left-div {
            display: flex;
            align-items: center;
            .client-reschedule-inner-left-img {
              height: 20px;
            }
            .client-reschedule-inner-left-span {
              color: #122f4e;
              font-weight: 500;
              font-family: Arial, Helvetica, sans-serif !important;
              font-size: 12px;
              padding: 0px 10px;
              white-space: nowrap; /* Prevent text from wrapping */
              overflow: hidden; /* Hide overflow content */
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .client-reschedule-confirm-end {
    width: 83%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
    .client-reschedule-confirm-button {
      background-color: #4a70c6;
      color: white;
    }
  }

  @media screen and (max-width: 1024px) {
    .client-reschedule-confirm-top {
      justify-content: center;
      gap: 25px;
      height: auto;
    }

    .client-reschedule-confirm-middle {
      height: auto;
    }

    .client-reschedule-confirm-end {
      height: 10%;
      align-items: center;
      width: 95%;
    }
  }

  @media screen and (max-width: 820px) {
    .client-reschedule-confirm-top {
      height: auto;
      gap: 20px;
    }
    .client-reschedule-confirm-middle {
      height: auto;
      margin: 20px 0px;
      .client-reschedule-confirm-box {
        width: 90%;
        height: 100%;

        .client-reschedule-inner-box {
          display: block;

          .client-reschedule-inner-box-left {
            width: 100%;
            .client-reschedule-inner-left-div {
              margin: 10px 0px 0px 0px;
              width: 100%;
              align-items: flex-start;

              .client-reschedule-inner-left-del {
                white-space: unset;
                margin: auto 0px;
              }
            }
          }

          .client-reschedule-inner-box-right {
            width: 100%;
            padding: 10px;
            .client-reschedule-inner-left-div {
              margin: 10px 0px 0px 0px;
              width: 100%;
              padding: 0px;
              align-items: flex-start;

              .client-reschedule-inner-left-span {
                white-space: unset;
                margin: auto 0px;
              }
            }
          }
        }
      }
    }
    .client-reschedule-confirm-end {
      height: 10%;
      width: 100%;
      justify-content: center;
      align-items: center;
      .client-reschedule-back-button {
        width: 45%;
      }
      .client-reschedule-confirm-button {
        width: 45%;
        background-color: #4a70c6;
        color: white;
      }
    }
  }
}

.client-reschedule-confirm-dialogue-reload-pop-up {
  max-width: 335px;
  padding: 30px;
  .client-confirm-dialogue-X-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 10px;
    cursor: pointer;
  }
  .client-confirm-dialogue-Heading {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #122f4e;
    font-size: 20px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 0px;
  }
  .client-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .Warning-button {
      background-color: #6987ca;
      box-shadow: none;
      margin: 5px 25px;
      color: white;
    }
    :hover {
      color: white !important;
      background-color: #6987ca !important;
    }
  }
}
