.error-message {
  background-color: rgba(182, 0, 0, 1);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
  text-align: center;
  margin: 0px 5px;
  /* border: 2px solid black; */
}

.error-message-1 {
  color: white;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.error-message-container {
  display: flex;
  justify-content: center;
  /* height: 40px; */
  /* border: 2px solid black; */
}

.drive-error-message {
  background-color: rgba(255, 239, 238, 1);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
  text-align: center;
  margin: 0px 5px;
  /* border: 2px solid black; */
}

.cancel-late-tag {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.drive-error-message-1 {
  color: rgba(125, 18, 18, 1);
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.drive-error-message-container {
  display: flex;
  justify-content: center;
  /* height: 40px; */
  /* border: 2px solid black; */
}

.pad-right-7 {
  padding-right: 7px;
}

.Client-reschedule-error-message {
  background-color: #ffefee;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 10px;
  text-align: center;
  margin: 0px 5px;
  gap: 10px;
  width: 100%;
  max-width: 1024px;
}

.Client-reschedule-error-message .css-ahj2mt-MuiTypography-root {
  width: 90%;
}

.Client-reschedule-error-message-1 {
  color: #7d1212;
  font-family: Helvetica;
  font-weight: 500;
  font-size: 14px;
}

.Client-reschedule-error-message-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.Client-reschedule-Warning-img {
  height: 25px;
}

.client-email {
  color: #7d1212;
}

@media (max-width: 728px) {
  .Client-reschedule-error-message {
    padding: 10px 0px 0px 0px;
    gap: 0px;
    align-items: flex-start;
  }

  .Client-reschedule-error-message .css-ahj2mt-MuiTypography-root {
    padding: 0px 10px 0px 0px;
    width: 100%;
  }

  .Client-reschedule-Warning-img {
    height: 25px;
    margin: 10px 0px 10px 10px;
  }

  .Client-reschedule-error-message-1 {
    margin: 5px 0px 10px 0px;
  }
}